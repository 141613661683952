<template>
  <v-navigation-drawer
    id="core-navigation-drawer"
    v-model="drawer"
    :dark="barColor !== 'rgba(228, 226, 226, 1), rgba(255, 255, 255, 0.7)'"
    :expand-on-hover="expandOnHover"
    :right="$vuetify.rtl"
    :src.sync="drawerImage"
    mobile-breakpoint="960"
    app
    width="260"
    v-bind="$attrs"
  >
    <template v-slot:img="props">
      <v-img :gradient="`to bottom, ${barColor}`" v-bind="props" />
    </template>

    <v-divider class="mb-1" />

    <v-list dense nav>
      <v-list-item>
        <v-list-item-avatar class="align-self-center" color="white" contain>
          <v-img src="/img/FinLogo.png" max-height="36" />
        </v-list-item-avatar>

        <v-list-item-content>
          <v-list-item-title class="display-1" v-text="profile.title" />
          <router-link class="text-right noline" to="/changelog"
            ><version
          /></router-link>
        </v-list-item-content>
      </v-list-item>
    </v-list>

    <v-divider class="mb-2" />

    <v-list expand nav>
      <div />

      <template v-for="(item, i) in computedItems">
        <base-item-group
          v-if="item.children"
          :key="`group-${i}`"
          :item="item"
        ></base-item-group>
        <base-item v-else-if="item.visible" :key="`item-${i}`" :item="item" />
      </template>

      <div />
    </v-list>
  </v-navigation-drawer>
</template>

<script>
// Utilities
import { mapState } from "vuex";
import Version from "@/components/Version";

export default {
  name: "DashboardCoreDrawer",
  components: {
    Version,
  },

  props: {
    expandOnHover: {
      type: Boolean,
      default: false,
    },
  },

  data: () => ({
    items: [
      {
        icon: "mdi-view-dashboard",
        title: "dashboard.page title",
        to: "/dashboard",
      },
      {
        title: "tickets.page title",
        icon: "mdi-ticket-account",
        to: "/pages/tickets",
        permission: ["answer tickets"],
      },
      {
        title: "clients.page title",
        icon: "mdi-home-group",
        to: "/pages/clients",
        permission: ["edit clients"],
      },
      {
        title: "projects.page title",
        icon: "mdi-home",
        to: "/pages/projects",
        permission: ["edit projects"],
      },
      {
        title: "quizzes.page title",
        icon: "mdi-forum",
        to: "/pages/quizzes",
        permission: ["edit quizzes"],
      },
      {
        title: "users.page title",
        icon: "mdi-account-multiple",
        to: "/pages/users",
        permission: ["edit users"],
      },
      {
        title: "roles.page title",
        icon: "mdi-account-key",
        to: "/pages/roles",
        permission: ["edit roles"],
      },
      {
        title: "configurator.page title",
        icon: "mdi-application-cog",
        to: "/pages/configurator",
        permission: ["edit projects"],
      },
      {
        title: "Debug",
        icon: "mdi-cog",
        group: "/debug",
        permission: ["debug"],
        children: [
          {
            title: "Routes",
            to: "routes",
          },
        ],
      },
    ],
  }),

  computed: {
    ...mapState(["barColor", "showBarImage", "barImage", "theme"]),
    drawer: {
      get() {
        return this.$store.state.drawer;
      },
      set(val) {
        this.$store.commit("SET_DRAWER", val);
      },
    },
    computedItems() {
      return this.items.map(this.mapItem);
    },
    profile() {
      return {
        avatar: true,
        title: this.$t("project.name"),
      };
    },
    drawerImage() {
      if (this.showBarImage) {
        return this.barImage;
      }
      return null;
    },
  },

  methods: {
    mapItem(item) {
      const hasPermission = this.$store.getters.menuHasPermission(item);
      return {
        ...item,
        visible: hasPermission,
        children:
          hasPermission && item.children
            ? item.children.map(this.mapItem)
            : undefined,
        title: this.$t(item.title),
      };
    },
  },
};
</script>

<style lang="sass">
@import '~vuetify/src/styles/tools/_rtl.sass'

#core-navigation-drawer
  .v-list-group__header.v-list-item--active:before
    opacity: .24

  .v-list-item
    &__icon--text,
    &__icon:first-child
      justify-content: center
      text-align: center
      width: 20px

      +ltr()
      margin-right: 24px
      margin-left: 12px !important

      +rtl()
      margin-left: 24px
      margin-right: 12px !important
    .display-1
      font-size:26px !important
      line-height: 30px

  .v-list--dense
    .v-list-item
      &__icon--text,
      &__icon:first-child
        margin-top: 10px

  .v-list-group--sub-group
    .v-list-item
      +ltr()
      padding-left: 8px

      +rtl()
      padding-right: 8px

    .v-list-group__header
      +ltr()
      padding-right: 0

      +rtl()
      padding-right: 0

      .v-list-item__icon--text
        margin-top: 19px
        order: 0

      .v-list-group__header__prepend-icon
        order: 2

        +ltr()
        margin-right: 8px

        +rtl()
        margin-left: 8px
</style>
